export class SearchInitializer {
  constructor(controller, params) {
    this.controller = controller
    this.params = params
    this.controllerStickyOptions = {}
  }

  init() {
    this.initValues()
    this.initFilters()
    this.initColumns()
    this.initShareViewModal()
    this.initTableStickyHeaders()
  }

  initValues() {
    this.controller.filters = {}
    this.controller.columns = {}
    this.controller.tabs_columns = {}
    this.controller.facetFilters = {}
    this.controller.q = ''
    this.controller.sort = {}
    this.controller.tabAction = ''
    this.controller.myTeam = ''
    this.controller.customTabPosition = ''
    this.controller.customTabId = ''
  }

  initFilters() {
    if (!this.controller.searchFiltersTarget) {
      return
    }
    const params = this.controller.desirialize(this.controller.searchFiltersTarget.value) || {}
    this.controller.filters = params.filters || {}
    this.controller.facetFilters = params.facetFilters || {}
    this.controller.q = params.q || ''
    this.controller.sort = params.sort || {}
    this.controller.myTeam = params.myTeam || ''
  }

  initColumns() {
    if (!this.controller.hasColumnsTarget) {
      return
    }
    const columns = this.controller.desirialize(this.controller.columnsTarget.value) || {}
    this.controller.columns = columns
  }

  initShareViewModal() {
    const obj = window.location.search
    if (!obj) {
      return
    }
    const queryObj = new URLSearchParams(obj)
    const shareViewData = queryObj.get('share_view')
    if (!shareViewData) {
      return
    }
    const decodedViewParams = this.controller.desirialize(shareViewData)
    swal.fire({
      title: '',
      html: '<b>' + window.I18n.t('js.import_shared_view', {
        view_name: decodedViewParams.name,
        user_name: decodedViewParams.user,
      }) + '</b>',
      icon: 'info',
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.controller.sharedViewCreateLinkTarget.click()
      }
    })
  }
}
